import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box, Image, Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { TobiqueSeal } from '../tobique-seal'

import ApgSeal from './apg-seal'
import { XcmSeal } from './xcm-seal'

const LicenseImage = styled.img`
  max-height: 45px;
  margin-left: 6px;
  margin-right: 6px;
`

function LicenseLogos(props) {
  return (
    <Box
      display="inline-flex"
      justifyContent="inherit"
      alignItems="inherit"
      flexWrap="inherit"
    >
      {props.licenses.showAppSeal && (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <XcmSeal />
        </Box>
      )}
      {props.licenses.showAppSeal && (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <ApgSeal />
        </Box>
      )}
      {props.showTobiqueSeal ? (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <TobiqueSeal />
        </Box>
      ) : (
        <Link
          to="https://certificates.gamingcommission.ca/KGCCertificates.aspx?id=8dfbc07a-5d01-4c49-925c-d7b800857744"
          target="_blank"
        >
          <LicenseImage
            size="fixed"
            height={45}
            src={props.translate(`${props.license}-logo`)}
            alt={props.license}
          />
        </Link>
      )}
      <Link to="https://www.gamblingtherapy.org/en" target="_blank">
        <LicenseImage
          size="fixed"
          height={45}
          src={props.translate('gamblingtherapy-logo')}
          alt="Gambling Therapy"
        />
      </Link>
      <Link to="/responsible-gaming" target="_blank">
        <Image
          size="fixed"
          height={45}
          src={props.translate('license.asset.18plus')}
          alt="18+"
        />
      </Link>
    </Box>
  )
}

export default withTranslate(LicenseLogos)

LicenseLogos.propTypes = {
  license: PropTypes.string.isRequired,
  licenses: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  showTobiqueSeal: PropTypes.bool,
}
